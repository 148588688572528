<template>
  <div class="tiles__component" :class="`layout-${sanitizedBlockData.layout}`">
    <div v-if="sanitizedBlockData.title" class="tiles__title">
      {{ sanitizedBlockData.title }}
    </div>
    <div class="tiles__container" :style="{ gridTemplateColumns }">
      <a
        v-for="(tile, tilesIndex) in sanitizedBlockData.tiles"
        :href="tile.ctaLink"
        :key="`tiles-${tilesIndex}`"
        class="tiles__tile"
        :target="tile.ctaLink.includes('http') ? '_blank' : '_self'"
      >
        <nuxt-img
          class="tiles__tile__image"
          :src="$device.isDesktop ? tile.desktopImageUrl : tile.mobileImageUrl"
          :alt="tile.desktopImageAlt"
        />
        <div class="tiles__tile__content">
          <h4 v-if="tile.title" class="tiles__tile__content__title">
            {{ tile.title }}
          </h4>
          <h5
            v-if="tile.text && sanitizedBlockData.layout === 'post'"
            class="tiles__tile__content__text"
          >
            {{ tile.text }}
          </h5>
          <div v-if="tile.ctaLabel" class="tiles__tile__content__cta-label">
            {{ tile.ctaLabel }}
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { computed, useContext, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Tiles',
  props: {
    blockData: {
      type: Object,
      required: true,
      validator: (value) => !!(value?.tiles?.length > 0),
    },
  },
  setup(props) {
    const { blockData } = props;
    const {
      app: { localePath },
    } = useContext();

    const link = (ctaLink) => {
      return ctaLink
        ? ctaLink.includes('http')
          ? ctaLink
          : localePath(`${ctaLink}`)
        : localePath(`/`);
    };

    const sanitizedBlockData = computed(() => ({
      ...blockData,
      title: blockData.title || '',
      layout: blockData.layout || 'category',
      tiles:
        blockData.tiles?.map((tile) => ({
          ...tile,
          title: tile.title || '',
          text: tile.text || '',
          ctaLabel: tile.ctaLabel || '',
          ctaLink: link(tile.ctaLink),
          desktopImageUrl:
            (tile?.desktopImage?.url && `${tile?.desktopImage?.url}`) ||
            '/icon.png',
          desktopImageAlt: tile?.desktopImage?.alt || '',
          mobileImageUrl:
            (tile?.mobileImage?.url && `${tile?.mobileImage?.url}`) ||
            '/icon.png',
        })) || [],
    }));

    const gridTemplateColumns = computed(() =>
      '1fr '.repeat(Number.parseInt(blockData.tilesPerRow || '3', 10)).trim()
    );

    return {
      sanitizedBlockData,
      gridTemplateColumns,
    };
  },
});
</script>

<style lang="scss" scoped>
.tiles {
  &__component {
    margin: 2rem 0.9375rem;
  }
  &__title {
    text-align: center;
    @include h2;
    margin-bottom: 3rem;
  }
  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.5rem;
  }
  &__tile {
    @include flex-column;
    position: relative;
    width: 100%;
    &__image {
      position: relative;
      width: 100%;
    }
    &:hover {
      .tiles__tile__content__cta-label {
        text-decoration: underline;
      }
    }
  }
  &__component.layout-category {
    .tiles {
      &__tile {
        position: relative;
        background-color: var(--c-white);
        &__content {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          @include flex-column;
          justify-content: center;
          align-items: center;
          &__title {
            padding: 0.125rem 0.25rem;
            margin-bottom: 1rem;
            @include h3;
            background-color: var(--c-primary);
          }
          &__cta-label {
            @include h5;
          }
        }
      }
    }
  }
  &__component.layout-post {
    @include desktop-boxed-inner;
    .tiles {
      &__tile {
        &__image {
          margin: 0 0 1.875rem;
        }
        &__content {
          &__title {
            padding: 0 0 0.9375rem;
            border-bottom: 1px solid var(--c-primary-lightest);
            @include h4;
            line-height: var(--line-height-20);
          }
          &__text {
            padding: 0.9375rem 0 1.25rem;
            @include p-small;
          }
          &__cta-label {
            @include h5;
            font-weight: var(--font-weight-normal);
          }
        }
      }
    }
  }
  @include to-tablet-max {
    &__component {
      padding-right: 1.25rem;
      padding-left: 1.25rem;
    }
    &__title {
      text-align: left;
      @include h3;
      color: var(--c-primary);
      margin-bottom: 2rem;
    }
    &__container {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  }
}
</style>
